.App {
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #d4d7df;
}
.Calendly{
  padding: 1rem;
}
 .App > p{
  font-weight: 900;
  font-family: Felix Tilting;
  font-size: 1.5rem;
  padding-top: 2rem;
 }
 .App > h1{
  font-family: Felix Tilting;
  font-size: 1.5rem;
  padding: 2rem;
 }
.SiteTitle{

  font-size: 2rem;
  font-family: Felix Tilting;
  color: #61dafb;
}
@media screen and (max-width:1024px) {
  .wrapper{
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #120c4e;
  align-items: center;
    display: flex;
    flex-direction: column;
    line-height: 3rem;
    padding-bottom: 5rem;
    position: relative;
  }

}
.wrapper{
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #120c4e;
  margin-left: 1rem;
  margin-right: 1rem;
  justify-content: space-around;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 5rem;
  position: relative;
}

.wrapper .box:hover{
  color: rgb(230, 186, 130);
  position: relative;
  z-index: 2;
  text-shadow: 0 10px 5px black;
}
.wrapper > a {
  text-shadow: 0 10px 15px black;
  font-weight: 800;
  font-size: 1.7rem;
  color: rgb(28, 20, 70);

}
.wrapper .box:hover{
  color: rgb(230, 186, 130);
  position: relative;
  z-index: 1;
  text-shadow: 0 10px 5px black;
}
.wrapper a:hover{
  color: rgb(230, 186, 130);
  position: relative;
  z-index: 2;
  text-shadow: 0 10px 5px black;
}
/** website previews **/

.effect{
  width: 30rem;
  position: absolute;
  pointer-events: none;
  z-index: 1;
  box-shadow: 0 5px 20px black;
  top: 0;
 
  transform: translate(-5%) scale(.2);
  opacity: 0;
}
.wrapper .box:hover ~ .effect{
  left: 0;
  transform: translate(0%,19%) scale(.75);
  opacity: 1;
  border-radius: .5rem;
}

.effect2{
  width: 30rem;
  position: absolute;
  pointer-events: none;
  z-index: 2;
  box-shadow: 0 5px 20px black;
  top: 0;
 
  transform: translate(-5%) scale(.2);
  opacity: 0;
}
.wrapper .box2:hover ~ .effect2{
  left: 0;
  transform: translate(20%,22%) scale(.75);
  opacity: 1;
  border-radius: .5rem;
}
.effect3{
  width: 30rem;
  position: absolute;
  pointer-events: none;
  z-index: 2;
  box-shadow: 0 5px 20px black;
  top: 0;
 
  transform: translate(-5%) scale(.2);
  opacity: 0;
}
.wrapper .box3:hover ~ .effect3{
 
  transform: translate(11%,22%) scale(.75);
  opacity: 1;
  border-radius: .5rem;
}
.effect4{
  width: 30rem;
  position: absolute;
  pointer-events: none;
  z-index: 1;
  box-shadow: 0 5px 20px black;
  top: 0;
 
  transform: translate(-5%) scale(.2);
  opacity: 0;
}
.wrapper .box4:hover ~ .effect4{
  right: 0;
  transform: translate(9%,19%) scale(.75);
  opacity: 1;
  border-radius: .5rem;
}
.resume{
  padding-bottom: 10rem;
  align-items: center;
  margin: 10px;
  font-family: 'Times New Roman', Times, serif;

}
.resumepaper{
  
  align-items: center;
  padding: 10px;
  background-color: antiquewhite;
  box-shadow: 10px 10px 5px rgb(100, 94, 94);
}
.tech{
  height: 5rem;
  width: 5rem;
}
.websitepagetop{
  font-size: 1.3rem;
  
  font-family: Felix Tilting;
  padding: 1rem;
}
p
{
    font-size: 17px;
    color: #120c4e;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
