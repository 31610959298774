.App4 {
  align-items: center;
  text-align: center;
  justify-content: center;
  
  
  }
  .form{
    min-width: 20rem;
    margin: 0px;
    background-color: burlywood;
  }

  .form5{
    margin: 1rem;
    min-width: 10rem;
    
   
  }

 
  .containersc{
      align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 90rem;
  padding: 1rem;
  
  }
  